import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'

// Third Party
import styled from 'styled-components'
import VacancyPreview from './VacancyPreview'

const BlogContainer = styled.div``

const TitleBlock = styled.div`
  background-color: rgb(50, 50, 50, 0.05);
  mix-blend-mode: normal;

  & h3 {
    font-family: ${(props) => props.theme.font.family.secondary};
    font-size: ${(props) => props.theme.font.size['40']};
    line-height: 48px;
    color: ${(props) => props.theme.color.face.main};
    text-transform: uppercase;
  }
`

interface VacancyOverviewProps {
  // eslint-disable-next-line
  fields: GatsbyTypes.WpPage_Flexcontent_Flex_VacancyOverview
}

const VacancyOverview: React.FC<VacancyOverviewProps> = ({ fields }) => {
  const {
    vacancies: { edges: vacanciesEdges },
    // eslint-disable-next-line
  } = useStaticQuery<GatsbyTypes.ZZQuery>(graphql`
    query {
      vacancies: allWpVacature(sort: { order: DESC, fields: date }) {
        edges {
          node {
            ...generalVacatureFragment
          }
        }
      }
    }
  `)

  return (
    <section id="vacatures" className="vacancies">
      <TitleBlock>
        <div className="container pb-3">
          <div className="row py-5 justify-content-center">
            <h3>Vacatures</h3>
          </div>
        </div>
      </TitleBlock>
      <div className="container mt-n5">
        <div className="row">
          <div className="col-lg-9 mx-auto">
            <BlogContainer>
              {vacanciesEdges?.map((vacancy: any, index: any) => (
                <VacancyPreview
                  right={false}
                  post={vacancy.node}
                  className="col-lg-12"
                  key={index}
                />
              ))}
            </BlogContainer>
          </div>
        </div>
      </div>
    </section>
  )
}

export default VacancyOverview
