import React from 'react'
import { Link } from 'gatsby'
import Plaatjie from '@ubo/plaatjie'

// Images
import Clock from 'img/clock.inline.svg'
import Arrow from 'img/arrow.inline.svg'

// Components
import ParseContent from 'components/shared/ParseContent'

// Third Party
import styled, { css } from 'styled-components'
import { motion } from 'framer-motion'
import ButtonDefault from 'components/elements/Button/ButtonDefault'

const StyledVacancyPreview = styled(motion.div)<{ layout: string }>`
  background-color: ${(props) => props.theme.color.face.light};
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.3);
  height: auto;
  box-shadow: 0px 0px 30px rgba(0, 0, 0, 0.3);
  border-radius: 29px;
`

const ContentContainer = styled.div`
  bottom: 0;
  left: 0;
  padding: 0px 15px;

  & h2 {
    color: ${(props) => props.theme.color.text.light};
    font-weight: ${(props) => props.theme.font.weight.xl};
  }

  & h3 {
    font-size: ${(props) => props.theme.font.size[24]};
    font-family: ${(props) => props.theme.font.family.secondary};
    text-align: initial;
    font-weight: 400;
  }

  @media (max-width: 991px) {
    & p {
      width: 100%;
    }
  }
`

const Icon = styled(Plaatjie)`
  height: 25px;
  width: auto;
`

interface VacancyPreviewProps {
  right?: boolean
  className?: string
  // eslint-disable-next-line
  post: GatsbyTypes.WpVacature
}

const VacancyPreview: React.FC<VacancyPreviewProps> = ({
  className = '',
  post,
  right = false,
}) => (
  <StyledVacancyPreview
    whileHover={{ scale: 1.03 }}
    className={`d-flex align-items-center mb-5 ${className}`}
    right={right}
  >
    <div className="position-relative w-100 d-flex flex-wrap align-items-center py-4">
      <ContentContainer className="h-100">
        <h3 className="mb-4">{post.title}</h3>
        <div className="d-flex flex-wrap mb-3 pb-2">
          {post.blogPreview?.usps?.map(({ description, icon }, index) => (
            <p>
              <Icon image={icon} alt="" />
              <span className="mr-3 ml-2">{description}</span>
            </p>
          ))}
        </div>
        <ParseContent
          content={post.blogPreview?.description}
          className="mb-3"
        />
        <div className="d-flex justify-content-lg-end">
          <ButtonDefault
            to={post.uri || ''}
            className="text-main text-uppercase"
          >
            Bekijk vacature
          </ButtonDefault>
        </div>
      </ContentContainer>
    </div>
  </StyledVacancyPreview>
)

export default VacancyPreview
